.purple {
    color: #c590c0
  }
  .green {
    color: #87cb9c
  }
  .blueLight {
    color: #77b0dd
  }
  .red {
    color: #f47f64
  }
  .orange {
    color: #ffb32c
  }
  .blue {
    color: #0080ff
  }
  .blueGreyDark {
    color: #0a2756
  }
  .blueGrey {
    color: #3b5277
  }
  .grey {
    color: #8997ae
  }
  .greyLight {
    color: #c4cbd6
  }
  .greyWhite {
    color: #ebeef2
  }
  .white {
    color: #ffffff
  }
  
  .purpleBackground {
    background-color: #c590c0
  }
  .greenBackground {
    background-color: #87cb9c
  }
  .blueLightBackground {
    background-color: #77b0dd
  }
  .orangeBackground {
    background-color: #f47f64
  }
  .yellowBackground {
    background-color: #ffb32c
  }
  .blueBackground {
    background-color: #0080ff
  }
  .blueGreyDarkBackground {
    background-color: #0a2756
  }
  .blueGreyBackground {
    background-color: #3b5277
  }
  .greyBackground {
    background-color: #8997ae
  }
  .greyLightBackground {
    background-color: #c4cbd6
  }
  .greyWhiteBackground {
    background-color: #ebeef2
  }

  